// 橄榄枝健康用户注册使用协议

const manual1 = [
  {
		title: '感谢您选择使用我们的产品和服务！',
		content: [
			{
				title: '',
				content: '我们”是广州橄榄医院有限公司（下称“橄榄医院”），注册地位于广州市白云区岗贝路148号3栋405室。'
			},
			{
				title: '',
				content: '“我们的服务”是指我们通过我们平台的客户端（包括但不限于H5网页、小程序）向您提供的互联网平台信息技术服务。我们的服务由我们和我们分布于各地的各分支机构、关联公司及合作伙伴共同为您提供。'
			},
			{
				title: '',
				content: `为使用我们的服务（指我们通过我们的平台向您提供的互联网平台信息技术服务，以下简称“本服务”），您应当阅读并遵守《用户服务协议》（以下简称“本协议”）。您在加入我们之前，已确认您具有注册和使用我们服务相应的民事行为能力，请认真阅读本协议，确保您充分理解本协议中各条款，并慎重选择接受或不接受本协议。除非您已充分阅读并接受本协议所有条款，否则您无权使用我们的服务。您对本服务的登录、查看等使用行为即视为您已阅读并同意本协议的约束，且完全认可、接受橄榄医院的统一管理。`
			},
			{
				content: '如果您因年龄（未满18周岁）、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称"监护人"）的陪同下阅读和判断是否同意本协议，并特别注意未成年人使用条款。如果您是中国大陆地区以外的用户，您订立或履行本协议还需要同时遵守您所属和/或所处国家或地区的法律。'
			},
			{
				content: '如果您是中国大陆地区以外的用户，您订立或履行本协议还需要同时遵守您所属和/或所处国家或地区的法律。'
			}
		]
	},
	{
		title: '一、用户资格',
		content: [
			{
				title: '',
				content: '您确认，在您开始使用我们的服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果，且橄榄医院有权注销您的账号，并向您及您的监护人索偿。'
			}
		]
	},
	{
		title: '二、权利与义务',
		content: [
			{
				title: '（一）用户权利及与义务',
				content: [
					{
						title: '1．用户注册',
						content: '若您注册成为橄榄医院的会员，您应接受如下的约定：'
					},
					{
						title: '',
						content: '（1）申请会员时，您应当提供真实、准确、即时、完整及有效的注册信息，保证不以他人资料在橄榄医院进行注册或认证；'
					},
					{
						title: '',
						content: '（2）维护并及时更新注册信息以保持其真实、准确、即时、完整及有效。若您提供的信息不真实、不准确、不完整，橄榄医院有权暂停或终止会员服务；'
					},
					{
						title: '',
						content: '（3）如果因您所提供的注册资料不合法、不真实、不准确或未及时更新，从而导致相关法律责任或不利后果的，您将承担相应的法律责任及不利后果。'
					},
					{
						title: '',
						content: '（4）您已知悉，在橄榄医院注册过程中，因安全性、内部管理等原因，部分初始注册资料可能将无法再次更改。因此，您应谨慎核查此部分注册资料，确保正确填写。'
					},
					{
						title: '2．用户权利',
						content: '您有权在橄榄医院上享受我们的平台上的合作伙伴提供的服务以及相关配套服务，具体的服务内容及服务主体以产品页面的展示及实际提供者为准。'
					},
					{
						title: '',
						content: '您拥有在我们的平台上使用自行注册的您名和密码之权利，但不得以任何形式擅自转让或授权他人使用自己的帐号。您如发现其他用户有违法或违反本服务协议的行为，可以向橄榄医院进行反映和要求处理。						'
					},
					{
						title: '3．用户义务',
						content: '您单独承担发布内容的责任。您对服务的使用是根据所有适用于橄榄医院的国家法律、地方法律和国际法律标准的。您应遵守相关法律法规，不应从事如下违反法律法规的规定，影响正常服务提供或损害他人合法利益的行为：'
					},
					{
						title: '',
						content: '（1）不应利用橄榄医院或相关服务危害国家安全、破坏政治稳定、泄露国家秘密，不侵犯国家、社会、集体利益和第三方的合法权益，不从事违法犯罪活动，不设立用于实施诈骗，传授犯罪方法，制作或者销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组，不利用网络发布涉及实施诈骗，制作或者销售违禁物品、管制物品以及其他违法犯罪活动的信息；'
					},
					{
						title: '',
						content: '（2）不应制作、发布、复制、查阅和传播、存储、链接下列信息：反对宪法所确定的基本原则的；危害国家安全，泄露国家秘密，颠覆国家政权，推翻社会主义制度，煽动分裂国家，破坏国家统一的；损害国家荣誉和利益的；煽动民族仇恨、民族歧视，破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；捏造、散布谣言，侵犯他人权利，扰乱经济、社会秩序，破坏社会稳定的；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；侮辱或者诽谤他人，侵害他人合法权益的；宣扬恐怖主义、极端主义的；违背当地风俗习惯的；含有法律、行政法规禁止的其他内容的；'
					},
					{
						title: '',
						content: '（3）不应从事下列危害计算机信息网络安全的活动：对网络服务及相关软硬件设施进行破解、破坏、删除、修改或者增加的；对计算机信息网络中存储或者传输的数据和应用程序进行删除、修改或者增加的；使用软件或硬件等方式窃取他人口令、非法入侵他人计算机系统；故意制作、传播计算机病毒等破坏性程序的；其他危害计算机信息网络安全的活动；'
					},
					{
						title: '',
						content: '（4）不应擅自复制和使用网络上未公开和未授权的文件。除相关著作权人明示同意，不应在网络中擅自破解、传播、下载或复制第三人享有著作权的软件或进行其他任何侵害他人知识产权的活动；'
					},
					{
						title: '',
						content: '（5）不得利用橄榄医院提供的各项服务，以倒卖、赠予、交换预约凭证等方式，影响正常预约秩序或者为自己牟取不正当利益，具体情形包括但不限于囤积号源、倒卖预约凭证、利用抢票软件刷票等；'
					},
					{
						title: '',
						content: '（6）不应发布其他违反法律法规、政策及公序良俗、社会公德或干扰微信和/或支付宝正常运营和侵犯其他用户或第三方合法权益内容的信息。'
					},
					{
						title: '4．责任承担',
						content: '如您在使用我们的服务过程中涉及上述行为中的一项或多项，则需要对自己的行为承担法律责任。承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在橄榄医院首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任等损失（含诉讼费、律师费等相关法律程序费用）后，您应立即给予橄榄医院等额的赔偿。此外，根据具体违法违规情形，橄榄医院有权作出独立判断，立即暂停或终止对您提供部分或全部服务，包括锁定、注销、删除帐号等措施。'
					},
					{
						title: '',
						content: '您知悉并理解，如果橄榄医院发现您的上述违法违规行为，依据相关法律法规的规定，橄榄医院有权或有义务立即停止相关服务，删除或屏蔽相关违规信息、服务评价，并视情况进行调查取证，保存有关记录，或向国家有关机关举报。同时，橄榄医院有权自主删除、屏蔽含有该内容的任何数据信息。橄榄医院将根据国家相关法律法规要求，对依法删除、屏蔽的数据信息予以记录、保存。'
					}
				]
			},
			{
				title: '（二）橄榄医院的权利与义务',
				content: [
					{
						title: '1．橄榄医院的权利',
						content: '（1）橄榄医院在必要时有权修改服务条款。如果您继续享有橄榄医院的服务，则被视为接受服务条款变动。'
					},
					{
						title: '',
						content: '（2）橄榄医院有权在一定的情形下以网络发布形式公布用户在使用橄榄医院提供的服务过程中的违法行为。'
					},
					{
						title: '',
						content: '（3）橄榄医院有权在不通知用户的前提下对用户在橄榄医院发布的下列各类信息进行删除或采取其它限制性措施：以炒作信用为目的的信息；存在欺诈等恶意或虚假内容的信息；违反公共利益或可能严重损害橄榄医院和其它用户合法利益的信息。'
					},
					{
						title: '2．橄榄医院的义务',
						content: '（1）橄榄医院通过国际互联网为用户提供网络服务，并承担本协议和服务条款规定的对用户责任和义务。'
					},
					{
						title: '',
						content: '（2）橄榄医院有义务对用户在注册使用橄榄医院中所遇到的与注册或服务有关的问题及反映的情况作出相应的回复。'
					},
					{
						content: '（3）橄榄医院发给用户的通告一般应通过重要页面的公告提示或其他常规通知形式进行提示。'
					}
				]
			},
			{
				title: '三、服务及免责声明',
				content: [
					{
						title: '（一）服务内容						',
						content: '您可通过橄榄医院接受多种服务，具体的服务内容、服务方式、服务对象及其他的要求以橄榄医院针对具体的服务发布的章程、服务条款和操作规则具体执行。'
					},
					{
						title: '（二）免责声明						',
						content: '您明确理解和同意橄榄医院对如下事宜不承担任何法律责任的声明：'
					},
					{
						title: '',
						content: '1．我们的内容由橄榄医院提供，橄榄医院在对平台进行合理管理和维护的同时，不对任何个人和组织直接或间接依据我们提供的信息而采取的任何行为承担责任。我们的一切内容均不得被视为在提供建议或做出推荐，不得被视作任何决定或行为的依据。'
					},
					{
						title: '',
						content: '2．您充分了解并同意，您必须为自己注册账户下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对我们的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。橄榄医院无法且不会对因前述风险而导致的任何损失或损害承担责任。'
					},
					{
						title: '',
						content: '3．您违反有关法律或者本协议之规定，使橄榄医院遭受任何损失，受到任何第三方的索赔，或任何行政管理部门的处罚，您应对橄榄医院提供补偿，包括但不限于合理的律师费用。'
					},
					{
						content: '4．尽管我们尽力确保本网站所有信息的正确性和适时性，但并不能保证其为完全准确和最新，我们对本网站信息的准确性、完整性和真实性不作任何承诺；对信息的迟延、失误和遗漏不承担任何责任。'
					}
				]
			},
			{
				title: '四、知识产权',
				content: [
					{
						title: '',
						content: '橄榄医院拥有本平台内所有内容的知识产权，包括文字、软件、声音、相片、录像、图表；广告中的全部内容；橄榄医院为用户提供的其他信息，所有这些内容受著作权、商标权、专利权和其它财产所有权法律的保护。任何人只有在获得橄榄医院的书面授权之后才能使用这些内容,，否则不能擅自复制、修改、制作衍生产品、进行还原工程、反向编译或以其它方式转移任何权利，或用于任何商业目的。橄榄医院的所有内容版权归原文作者和橄榄医院共同所有，任何人需要转载橄榄医院的内容，必须获得原文作者或橄榄医院的授权。'
					}
				]
			},
			{
				title: '五、用户信息和隐私保护',
				content: [
					{
						title: '（一）信息收集和存储',
						content: '1．个人信息的收集：若您注册并使用我们的服务，我们可能会收集您姓名、证件号码、婚姻状况、预约时间、开票时间、确认体检项目，可能还会收集您的性别、出生日期等信息。'
					},
					{
						title: '',
						content: '2．我们将按照法律法规规定,将境内收集的您的个人信息存储于中国境内。'
					},
					{
						title: '（二）信息安全和《用户隐私政策》',
						content: '1．我们将采取合理措施保护您的个人信息，以防止信息的丢失、盗用、未经授权访问或披露。为确保您个人信息的安全,我们具备严格的信息安全规定和流程、专门的信息安全团队以及信息安全应急预案等。'
					},
					{
						title: '',
						content: '2．我们充分尊重您在个人信息及隐私方面享有的权益，您可以阅读《用户隐私政策》进一步以了解您在使用产品或服务时，我们收集和使用您的个人信息的目的、方式和范围，您对您的个人信息的权利，以及我们为保护信息安全所采取的安全保护措施。'
					}
				]
			},
			{
				title: '六、协议变更和终止',
				content: [
					{
						title: '（一）协议变更',
						content: '根据国家法律法规变化及网站运营需要，橄榄医院有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上即生效，并代替原来的协议。您可随时登录查阅最新协议；您有义务不时关注并阅读最新版的协议及网站公告。如您不同意更新后的协议，可以且应立即停止接受橄榄医院依据本协议提供的服务；如您继续使用本网站提供的服务的，即视为同意更新后的协议。橄榄医院建议您在使用本站之前阅读本协议及本站的公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。'
					},
					{
						title: '（二）协议终止情形',
						content: '1．如因橄榄医院相关服务器或系统维护或升级的需要而需中断或暂停提供任何产品和服务的，橄榄医院将尽可能事先进行通告。同时您可随时单方终止使用产品和服务，您终止使用无需特别通知橄榄医院。您主动终止使用产品和服务时，本协议终止。'
					},
					{
						title: '',
						content: '2．在下列情况下，橄榄医院可以通过注销用户账号方式，单方面解除本协议：'
					},
					{
						title: '',
						content: '（1）在用户违反本协议相关规定时，橄榄医院有权暂停或终止向该用户提供服务。如该用户在橄榄医院暂停或终止提供服务后，再一次直接或间接或以他人名义注册为橄榄医院网站用户的，则橄榄医院有权再次暂停或终止向该用户提供服务'
					},
					{
						content: '（2）本协议修改或更新时，如用户表示不愿接受新的服务协议的，橄榄医院有权终止向该用户提供服务；'
					},
					{
						content: '（3）橄榄医院认为需终止提供服务的其他情况。'
					}
				]
			},
			{
				title: '七、其他',
				content: [
					{
						title: '（一）联系方式',
						content: '当你有其他的投诉、建议、个人信息及隐私保护相关问题时，请通过橄榄医院客户端提供的在线客服系统我们联系。你也可以将你的问题寄到如下地址：'
					},
					{
						title: '',
						content: '地址：广州市白云区岗贝路148号3栋405室/广州橄榄医院有限公司/客服部'
					},
					{
						title: '',
						content: '邮编：510000'
					},
					{
						title: '',
						content: '电话：13710442452  邵生'
					},
					{
						title: '（二）协议签订地',
						content: '本协议签订地约定为广州。'
					},
					{
						title: '（三）争议解决方式',
						content: '1．本协议的解释、效力及纠纷的解决，适用于中华人民共和国法律。'
					},
					{
						title: '',
						content: '2．若您和广州橄榄医院有限公司之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，您在此完全同意将纠纷或争议提交深圳国际仲裁院仲裁。'
					}
				]
			},
		],

	}
]

//橄榄枝用户支付协议
const manual2 = [
	{
		  title: '',
		  content: [
			  {
				  title: '',
				  content: '尊敬的客户，“我们”是广州橄榄医院有限公司（下称“橄榄医院”），“我们的服务”是指我们通过我们平台的客户端（包括但不限于H5网页、小程序，域名：https://wx.glztj.com/，下称“本平台”）向您提供的互联网平台信息技术服务。在您前，请仔细阅读如下服务条款：。'
			  }
		  ]
	  },
	  {
		  title: '总则',
		  content: [
			  {
				  title: '',
				  content: '本平台由橄榄医院营管理，并根据本协议的规定向用户提供服务。'
			  },
			  {
				title: '',
				content: '本协议双方为本平台用户与橄榄医院，适用于用户注册本平台，并在本平台获取预约体检、申请改期及退款等服务的全部活动。 在注册本平台之前，请务必认真阅读并充分理解本协议全部内容。勾选本协议前方的复选框，即视为用户已经充分理解和同意本协议全部内容，并签署了本协议。本协议已在用户与橄榄医院之间产生合同法律效力，用户使用本平台服务的全部活动将受到本协议的约束并应承担相应的责任和义务。'
			}
		  ]
	  },
	  {
		  title: '第一章 协议组成说明',
		  content: [
			  {
				  title: '',
				  content: [
					  {
						  title: '',
						  content: '1.1 本协议的内容包括本协议以下全部条款以及本平台已经公示发布的及将来可能公示发布的各项规则，该类规则均为本协议不可分割的一部分，与本协议以下所列条款具有同等法律效力'
					  },
					  {
						  title: '',
						  content: '1.2 本平台有权根据需要修改本协议的内容。如以下条款或本平台各项规则有任何变更，本平台将在本平台上刊载公告，不再向用户作个别通知。经修订的相关条款和规则一经公告，即于公告规定的特定生效日期自动生效；'
					  },
					  {
						  title: '',
						  content: '1.3 本协议不涉及用户与本平台的其他用户之间因网上交易而产生的法律关系及法律纠纷。但用户在此不可撤销地同意，将全面接受并履行与本平台其他用户在本平台签订的任何电子法律文本，并承诺按照该法律文本享有和（或）放弃相应的权利、承担和（或）豁免相应的义务；'
					  }
				  ]
			  },
			
		  ],
  
	  },
	  {
		title: '第二章 平台服务内容及服务费',
		content: [
			{
				title: '',
				content: '2.1 本平台向用户提供体检机构信息（包括但不限于医院、专业体检中心等机构）、以及该类机构可预约体检服务的相关信息、并提供在线预约及在线支付服务。'
			},
			{
				title: '',
				content: '2.2 为了交易的公平性及管理的便利性，本平台还为用户提供了体检前及体检后的咨询服务以及其它增值服务。'
			},
			{
				title: '',
				content: '2.3 用户按照体检服务的种类，支付相应的体检费用（包含平台服务费用）。用户在平台进行体检套餐订单支付后，需自觉遵守诚实守信原则，及时预约体检时间，并按预约时间前往对应的体检机构完成体检，超过预约时间本平台自动默认为已到检，超过预约时间后如需改期，请参照本协议中约定的改期规则进行处理。如因用户个人原因，未按照流程完成体检，所带来的后果及可能产生的相关服务费用由用户承担，与本平台无关。'
			},
			{
				title: '',
				content: '2.4本平台可根据实际需要对平台的售卖体检套餐价格进行修改和变更，本平台也可针对部分免费服务开始收费。前述修改、变更或开始收费前，本平台将会在相应的页面进行通知或公告。如果您不同意上述修改，变更或付费内容，则应停止使用该服务。'
			},
			{
				title: '',
				content: '2.5由于本平台的服务及套餐售卖价格由医院等第三方机构提供，在线售卖价格会随着市场及物价局定价存在波动，如果发生已售卖交易体检套餐及产品服务发生价格变更，在用户未进行体检服务之前，本平台有权利和义务要求用户退补差价，如果用户不同意上述补差价要求，本平台有权利终止该服务并进行退款。'
			}
		]
	},
	{
		title: '第三章 申请改期及退款权责',
		content: [
			{
				title: '3.1 关于改期：',
				content: '（1）改期界定：用户在本平台进行体检套餐订单支付，并完成体检时间预约；但因为客观或不可抗力原因，无法按预约时间进行体检，需要申请变更体检预约时间的，以下统称为改期。'
			},
			{
				title: '',
				content: '（2）每位用户可以无理由，直接通过网站改期3次。'
			},
			{
				title: '',
				content: '（3）3次改期后如需再次改期，请联系客服可改期。'
			},
			{
				title: '3.2 关于退款：',
				content: '（1）用户支付成功，如因客观原因无法按预约时间体检，可以全额退款。'
			},
			{
				title: '3.3 关于逾期未检：',
				content: '（1）逾期未检的订单，我们会先跟医院核实体检信息。'
			},
			{
				content: '（2）情况属实的逾期未检订单，可拨打客服电话（【 4009003732】）更改预约日期或申请退款。'
			},
			{
			  title: '3.4其它：',
			  content: '（1）本平台前端展示排期是根据第三方医院/机构提供，排期时间会由于客观因素实时变动，因此，平台用户已申请预约时间，并不代表预约成功，需以订单最终状态（预约成功）为最终标准。如遇特殊情况，不能预约成功，本平台有义务根据情况在用户提前前一天提前通知到用户，并与用户协商改期或全额退款。如用户同意以上处理办法，平台有权利单方面终止服务。'
		  },
		  {
			  content: '（2）在平台购买的服务中，因服务提供具有时效性，且部分服务在购买时即生效，因此，如果您在支付之日起3年内未使用购买的服务且经平台短信提示三次后，仍在3个月内未使用的，即视为平台已经提供服务，平台有权不进行退款，如有问题可以随时打客服电话咨询。'
		  }
		]
	},
	{
		title: '第四章 其他',
		content: [
			{
				title: '',
				content: '4.1 本协议的订立、执行、解释及争议解决均适用中华人民共和国法律（不包括香港、澳门、台湾地区）。本协议条款具有可分性，部分无效不影响其它部分效力。本平台对本协议拥有最终的解释权。'
			},
			{
			  title: '',
			  content: '4.2 如果你是本平台注册用户，本协议条款将对用户持续有效，有效期至用户注销或者被关闭账户后。'
		  },
		  {
			  title: '',
			  content: '4.3 当您有其他的投诉、建议、预约改期等相关问题时，请您将您的问题寄到如下地址或拨打座机电话:'
		  },
		  {
			  title: '',
			  content: '地址：广州市白云区岗贝路148号3栋405室/广州橄榄医院有限公司/客服部'
		  },
		  {
			  title: '',
			  content: '邮编：510000'
		  },
		  {
			  title: '',
			  content: '电话：13710442452  邵生'
		  },
		  {
			  title: '',
			  content: '4.4 如有任何争议，应友好协商解决，协商不成，应提交深圳国际仲裁院仲裁解决。'
		  }
		]
	}
  ]

//用户授权协议
const manual3 = [
	{
		  title: '感谢您选择使用我们的产品和服务！',
		  content: [
			  {
				  title: '',
				  content: '我们”是广州橄榄医院有限公司（下称“橄榄医院”），注册地位于广州市白云区岗贝路148号3栋405室。'
			  },
			  {
				  title: '',
				  content: '“我们的服务”是指我们通过我们平台的客户端（包括但不限于H5网页、小程序）向您提供的互联网平台信息技术服务。我们的服务由我们和我们分布于各地的各分支机构、关联公司及合作伙伴共同为您提供。'
			  },
			  {
				  title: '',
				  content: `广州橄榄医院有限公司（下称“橄榄医院”）深知个人信息对您的重要性，并会尽全力保护您的个人信息，我们致力于维持您对我们的信任，格守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时我们承诺：将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息，请在使用我们的服务前，仔细阅读并了解本用户隐私政策。`
			  },
			  {
				  content: '我们的部分服务基于其特殊原因及法律规定，可能会有独立的隐私政策，您在使用这些特定的服务时，除遵守本《用户隐私政策》外，还应当遵守相关服务所对应的独立的隐私政策，如相关服务所对应的独立的隐私政策中规定的内容与本隐私政策发生冲突，则在该服务中，以其所对应的独立的隐私政策的规定为准。'
			  },
			  {
				  content: '如果您有任何疑问、意见或建议，可按照本政策之“七、如何联系我们”载明的方式与我们联系。'
			  }
		  ]
	  },
	//   {
	// 	title: '隐私政策具体内容',
	// 	content: []
	//   },
	{
		title: '一、业务功能的个人信息使用规则',
		content: [
		{
			title: '（一）我们如何收集您的个人信息',
			content: '在您使用我们的产品和服务时，我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。'
		},
		{
			title: '1. 帮助您成为我们的注册用户及账户管理',
			content: '为向您提供账户注册、登录功能及服务，在您通过以下方式注册或登录本平台时，您需要提供如下账户注册信息：'
		},
		{
			content: '登录：当您登录时，我们会收集您的微信昵称、联系方式，用于账户管理及登录验证。在您使用我们的服务时，我们也会根据服务内容的需要，向您收集必要的信息，并在收集时向您做出明确的提示。'
		},
		{
			content: '我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。若您主动提供您的个人敏感信息即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。'
		},
		{
			title: '2. 为您提供平台搜索功能',
			content: '当您使用本平台提供的搜索功能时，我们会收集您查询的关键字信息以及您在使用我们的服务时所阅览或要求的其他信息和内容详情。为了给您带来更便捷的搜索服务并不断完善我们的服务，我们可能会使用浏览器网络存储机制和应用数据缓存，收集您设备上的信息并进行本地存储。关键词信息通常无法单独识别您的个人身份，不属于您的个人信息，不在本政策的限制范围内。只有当您的搜索关键词信息与您的其他信息有联结并可识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本政策对其进行处理与保护。如果您不提供上述权限、信息，将会影响您使用此功能。'
		},
		{
			title: '3. 为您提供体检预约服务功能',
			content: '在体检预约服务的过程中，需要您授权本平台收集您个人的姓名、证件号码、婚姻状况、预约时间、开票时间、确认体检项目，可能还会收集您的性别、出生日期等。其中证件号为体检机构确认体检人的必要信息；性别、婚姻可能在您具体的体检项目有所差异；出生日期为排除体检机构限制（部分机构会限制16岁以下人员进行体检）；您提供的联系方式用于接收体检预约的过程中相关服务确认短信。此外，为提供报告解读、检后管理等健康服务，需要您授权我们平台，在确保不泄露个人隐私情况下获取、查阅您的体检报告信息。如您选择不提供或不同意我们采集、使用以上个人信息，将导致您无法使用体检预约服务。'
		},
		{
			title: '4. 为您提供下单购买功能',
			content: '您可以在本平台在线购买相应的体检预约服务。在您使用该功能的过程中可能会需要进行支付，在支付过程中，您需要授权微信支付或支付宝进行款项结算，微信支付或支付宝可能会收集您的第三方支付帐号(支付宝账号、微信支付账号及银行卡信息)用于付款验证，以及收集您的收货地址信息，包括收货人、地址、手机号码。如您选择不提供以上个人信息，将导致您无法使用相关的支付功能，但不影响您使用基本浏览、搜索功能。'
		},
		{
			title: '（二）我们在您使用服务时获取的信息',
			content: ''
		},
		{
			title: '1. 日志信息',
			content: '当您使用我们的服务时，我们可能会自动收集相关信息并存储为服务日志信息。'
		},
		{
			title: '(1) 软件信息',
			content: '例如，软件的版本号、浏览器类型。为确保操作环境的安全或提供服务所需，我们会收集有关您使用的移动应用和其他软件的信息。'
		},
		{
			title: '(2) 服务日志信息',
			content: '例如，您在使用我们服务时搜索、查看的信息、服务故障信息、引荐网址等信息。'
		},
		{
			title: '2. 位置信息',
			content: '当您使用与位置有关的服务时，我们可能会记录您设备所在的位置信息，以便为您提供相关服务。'
		},
		{
			title: '',
			content: '(1) 我们会收集您的（基于GPS和网络）位置信息（我们仅收集您当时所处的地理位置，但不会用以判断您的行踪轨迹）来判断您所处的地点，以自动为您推荐您所在区域可以提供的产品/服务。如果您不提供上述信息，将会影响您使用此功能。'
		},
		{
			title: '',
			content: '(2) 您或其他用户在使用服务时提供的信息中可能包含您所在地理位置信息，例如您提供的帐号信息中可能包含的您所在地区信息，您或其他人共享的照片包含的地理标记信息。这些信息并非该业务功能运行所必需，但这些信息对改善服务质量、研发新产品或服务等有非常重要的意义。如果您不提供上述信息，将会影响您使用此功能。'
		},
		{
			title: '（三）我们如何使用您的个人信息',
			content: ''
		},
		{
			title: '1. 如何使用必要信息',
			content: '我们将按基于上述功能及主要目的使用您的个人信息，当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。'
		},
		{
			title: '2. 例外情形',
			content: '当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。但以下情形中除外:'
		},
		{
			title: '',
			content: '(1) 与个人信息控制者履行法律法规规定的义务与职责相关的。'
		},
		{
			title: '',
			content: '(2) 与国家安全、国防安全直接相关的。'
		},
		{
			title: '',
			content: '(3) 与公共安全、公共卫生、重大公共利益直接相关的。'
		},
		{
			title: '',
			content: '(4) 与刑事侦查、起诉、审判和判决执行等直接相关的。'
		},
		{
			title: '',
			content: '(5) 出于紧急情况下维护个人信息主体或其他个人的生命健康、财产安全等重大合法权益但又很难得到本人授权同意的。'
		},
		{
			title: '',
			content: '(6) 所涉及的个人信息是个人信息主体自行向社会公众公开的。'
		},
		{
			title: '',
			content: '(7) 根据个人信息主体要求签订和履行合同所必需的。'
		},
		{
			title: '',
			content: '(8) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。'
		},
		{
			title: '',
			content: '(9) 维护所提供产品及/或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障。'
		},
		{
			title: '',
			content: '(10) 法律、法规规定的其他情形。'
		},
		{
			title: '（四）我们如何委托处理、共享、转让、公开披露您的个人信息',
			content: ''
		},
		{
			title: '1. 委托处理',
			content: '平台中某些具体的模块或功能由其他服务提供商或其他合作伙伴提供，包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。'
		},
		{
			title: '',
			content: '对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本用户隐私政策以及其他任何相关的保密和安全措施来处理个人信息。'
		},
		{
			title: '2. 共享',
			content: '我们不会与本公司以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：'
		},
		{
			title: '',
			content: '(1) 在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息，我们将在您使用我们的服务时，以独立的条款/政策如实告知您信息接收方的姓名或者名称、联系方式、处理目的、处理方式和个人信息的种类。'
		},
		{
			title: '',
			content: '(2) 我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。'
		},
		{
			title: '',
			content: 'a. 为提供预约问诊以及体检服务等功能，我们将向具体就医医疗机构提供您的具体信息，包括有效证件（包括但不限于身份证、医保卡、护照）的号码、姓名、联系手机、出生日期、性别、住址等。'
		},
		{
			title: '',
			content: 'b. 若您在平台在线购买相应的产品和/或体检预约服务，您需要授权微信支付进行款项结算，微信支付可能会收集您的第三方支付帐号(支付宝账号、微信支付账号及银行卡信息)用于付款验证。'
		},
		{
			title: '',
			content: 'c. 与我们的关联公司共享：您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。'
		},
		{
			title: '',
			content: '(3) 仅为实现本政策中声明的目的，我们的某些服务将委托授权合作伙伴提供处理。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们将与合作伙伴约定委托处理信息的目的、期限、处理方式、个人信息的种类、保护措施等，并对合作伙伴的个人信息处理活动进行监督。具体如下。'
		},
		{
			title: '',
			content: 'a. 为实现第三方合作登录以及挂号订单回传，我们可能向具体就医机构提供您的挂号订单信息：订单编号，订单金额，订单状态，手机号，姓名，证件号，性别。'
		},
		{
			title: '',
			content: 'b. 为实现发送短信功能，我们可能向短信供应商提供您的：姓名，手机号，挂号信息。'
		},
		{
			title: '',
			content: 'c. 为实现网上支付功能，我们可能向银行提供您的：身份证图片，姓名，性别，手机号，银行卡号，身份证号，身份证有效期，联系地址，职业，国籍。'
		},
		{
			content: 'd. 为了实现微信信息推送，我们可能向微信提供您的：就诊人姓名，订单编号，挂号时间；'
		},
		{
			content: 'e. 为实现电话咨询功能，我们可能向华为、腾讯、云信提供您的手机号码；'
		},
		{
			title: '3. 转让',
			content: '我们不会将您的个人信息转让给任何公司、组织和个人，但以下情形除外：'
		},
		{
			title: '',
			content: '(1) 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。'
		},
		{
			title: '',
			content: '(2) 在涉及合并、收购或破产清算时，如涉及个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此用户隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。'
		},,
		{
			title: '4. 公开披露',
			content: '我们仅会在以下情形下，公开披露您的个人信息：'
		},
		{
			title: '',
			content: '(1) 获得您明确同意后。'
		},
		{
			title: '',
			content: '(2) 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。'
		},
		]
	},
	{
		title: '二、我们如何存储和保护您的个人信息',
		content: [
			{
				title: '（一）存储地点',
				content: '我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。'
			},
			{
			title: '（二）存储期限',
			content: '我们仅在为我们的产品及/或服务之目的所必须的最短期间内保存您的个人信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外：对于您的体检预约相关信息，我们会在您注销您的账户后继续保存15年；对于您在我们平台上下单购买的产品及/或服务信息、交易信息，我们会在您注销您的账户后继续保存3年；除法律另有规定外，对于您的其他信息，我们会在您注销您的账户时同步删除。'
		},
			{
				title: '（三）技术措施与数据保护措施',
				content: [
					{
						title: '',
						content: '1．我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如包括技术保护手段、管理制度控制、安全体系保障等诸多方面。'
					},
					{
						title: '',
						content: '2．我们的数据安全能力：我们使用的技术手段包括但不限于防火墙、加密（例如SSL）、去标识化或匿名化处理、访问控制措施等。此外，我们还会不断加强在您设备端的软件安全能力。例如，我们会在您的设备本地完成部分信息加密工作，以巩固安全传输；我们会了解您设备安装的应用信息和运行的进程信息，以预防病毒、木马等恶意程序。'
					},
					{
						title: '',
						content: '3．我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在存储期限内保留您的个人信息。'
					},
					{
						title: '（四）安全事件处理',
						content: '1．互联网环境并非百分之百安全，如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，将可能导致您的合法权益受损。'
					},
					{
						title: '',
						content: '2．在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况'
					},
					{
						content: '3．我们会尽力保护您的个人信息。我们也请您理解，任何安全措施都无法做到无懈可击。'
					}
				]
			},
			
		],
	},
	{
		title: '三、您的权利',
		content: [
			{
				title: '',
				content: '按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利:'
			},
			{
				title: '（一）访问您的个人信息',
				content: '您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以拨打客服电话与我们联系，具体方式详见本政策之“七、如何联系我们”。'
			},
			{
				title: '',
				content: '对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请与我们联系，具体方式详见本报告之“七、如何联系我们”。'
			},
			{
				title: '（二）更正您的个人信息',
				content: '当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。可以拨打客服电话与我们联系，具体方式详见本政策之“七、如何联系我们”。我们将在15个工作日内回复您的更正请求。'
			},
			{
				title: '（三）删除您的个人信息',
				content: '在以下情形中，您可以向我们提出删除个人信息的请求：'
			},
			{
				content: '1．如果我们处理个人信息的行为违反法律法规；'
			},
			{
			  content: '2．如果我们收集、使用您的个人信息，却未征得您的同意；'
		  },
		  {
			  content: '3．如果我们处理个人信息的行为违反了与您的约定；'
		  },
		  {
			  content: '4．如果您不再使用我们的产品或服务，或您注销了账号；'
		  },
		  {
			  content: '5．如果我们不再为您提供产品或服务；'
		  },
		  {
			  content: '若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。'
		  },
		  {
			  content: '当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。'
		  },
		  {
			  title: '（四）个人信息主体获取个人信息副本',
			  content: '您有权获取您的个人信息副本，您可以通过本政策之“七、如何联系我们”与我们联系获得个人信息副本。'
		  },
		  {
			  title: '（五）个人信息主体注销账户',
			  content: '您随时可注销此前注册的账户，您可以拨打客服电话与我们联系，具体方式详见本政策之“七、如何联系我们”。'
		  },
		  {
			  title: '',
			  content: '在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。'
		  },
		  {
			  title: '（六）约束信息系统自动决策',
			  content: '在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。'
		  },
		  {
			  title: '（七）响应您的上述请求',
			  content: '为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求我们将在15天内作出答复。'
		  },
		  {
			  content: '对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段(例如，要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际(例如，涉及备份磁带上存放的信息)的请求，我们可能会予以拒绝。'
		  },
		  {
			  content: '在以下情形中，我们将无法响应您的请求：'
		  },
		  {
			  content: '1．与个人信息控制者履行法律法规规定的义务相关的；'
		  },
		  {
			  content: '2．与网家安全、国防安全直接相关的；'
		  },
		  {
			  content: '3．与公共安全、公共卫生、重大公共利益直接相关的；'
		  },
		  {
			  content: '5．个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；'
		  },
		  {
			  content: '6．出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；'
		  },
		  {
			  content: '7．响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；'
		  },
		  {
			  content: '8．涉及商业秘密的；'
		  },
		  {
			  content: '若我们决定不响应您的请求，我们将向您告知该决定的理由，您也可通过本政策之“七、如何联系我们”提供的联系方式进行投诉。'
		  }
		]
	},
	{
		title: '四、我们如何处理儿童的个人信息',
		content: [
			{
				title: '',
				content: [
				  {
					  content: '我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不应创建自己的个人信息主体账户。'
				  },
				  {
					  content: '对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开被露此信息尽管当地法律和习俗对儿童的定义不同，但我们将不满14周岁的任何人均视为儿童。'
				  },
				  {
					  content: '如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。'
				  }
				]
			}
		]
	},
	{
		title: '五、您的个人信息如何在全球范围转移',
		content: [
			{
				title: '',
				content: '原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。'
			},
			{
				title: '',
				content: '如部分产品或服务涉及跨境，我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。若部分管辖区设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。'
			}
		]
	},
	{
		title: '六、本隐私政策如何更新',
		content: [
			{
				title: '',
				content: '我们可能会不时对本《用户隐私政策》进行修订，当本《用户隐私政策》发生修订时，修改后的政策一旦被张贴在本站上即生效，并代替原来的政策，您有权就修订后的《用户隐私政策》重新向我们授权确认。请您注意，在《用户隐私政策》变更后，我们将无法保证按照其变更前的内容收集、使用、处理和存储您的个人信息。只有在您确认修订后的《用户隐私政策》后，我们才会按照修订后的《用户隐私政策》收集、使用、处理和存储您的个人信息。因此，如果您不同意修订后的《用户隐私政策》，请您停止使用我们的产品及/或服务。您继续使用我们产品及/或服务的行为也同样会被视为您同意并接受我们修订后的《用户隐私政策》。'
			},
			{
				title: '',
				content: '对于重大变更，我们还会提供更为显著的通知(包括对于某些服务，我们会通过电子邮件发送通知，说明用户隐私政策的具体变更内容)。'
			},
			{
				title: '',
				content: '本政策所指的重大变更包括但不限于：'
			},
			{
				title: '',
				content: '1．我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、，个人信息的使用方式等；'
			},
			{
				content: '2．我们在所有权结构、组织架构等方面发生重大变化，如业务调整、破产并购等引起的所有者变更等；'
			},
			{
				content: '3．个人信息共享、转让或公开披露的主要对象发生变化；'
			},
			{
			  content: '4．您参与个人信息处理方面的权利及其行使方式发生重大变化；'
		  },
		  {
			  content: '5．我们负责处理个人信息安全的责任部门、联络方式及投诉果道发生变化时；'
		  },
		  {
			  content: '6．个人信息安全影响评估报告表明存在高风险时。'
		  },
		  {
			  content: '我们还会将本政策的旧版本存档，供您查阅。'
		  }
		]
	},
	{
		title: '七、如何联系我们',
		content: [
			{
				title: '',
				content: '当你有其他的投诉、建议、个人信息及隐私保护相关问题时，请通过橄榄医院客户端提供的在线客服系统我们联系。你也可以将你的问题寄到如下地址：'
			},
			{
				title: '',
				content: '地址：广州市白云区岗贝路148号3栋405室/广州橄榄医院有限公司/客服部'
			},
			{
				title: '',
				content: '邮编：510000'
			},
			{
				title: '',
				content: '电话：13710442452  邵生'
			},
			{
				title: '',
				content: '我们将尽快审核所涉问题，并在验证您的用户身份后的15个工作日内予以回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向深圳国际仲裁院提起仲裁。'
			},
			{
				title: '',
				content: '就您与其他平台存在的疑问、纠纷或争议，请您知悉我们仅与该等平台存在合作关系，无法控制该等平台，烦请直接联系前述平台予以处理。'
			}
		]
	},
  ]

export default [manual1, manual2, manual3]