<template>
  <div class="manual-detail">
    <div class="h1" v-for="(item, index) in content" :key="index">
      <h1 v-if="item.title">{{item.title}}</h1>
      <div class="sub" v-for="(_item, _index) in item.content" :key="_index">
        <p class="bold mb12" v-if="_item && _item.title">{{_item.title}}</p>
        <template v-if="_item">
        <p v-if="_item.content && (_item.content instanceof Array)">
          <template v-for="(__item, __index) in _item.content">
            <p :key="'_sub_title_'+__index" class="bold mb12" v-if="__item.title">{{__item.title}}</p>
            <p :key="'_sub_'+__index" class="mb12">{{__item.content}}</p>
          </template>
        </p>
        <p v-else>{{_item.content}}</p>
        </template>
      </div>
    </div>
    <div class="buy-btn">
      <div class="detail-price">
        <van-button type="primary" round style="width: 100%" @click="$emit('close')">返回</van-button>
      </div>
    </div>
  </div>
</template>

<script>
const titles = ['橄榄枝健康用户注册使用协议', '橄榄枝用户支付协议', '用户授权协议']
import CONTENT from './components/manualContent'
export default {
  name: 'manual-detail',
  props: {
    type: {
      type: String
    }
  },
  data () {
    return {
      id: 0,
      content: []
    }
  },
  created () {
    this.id = this.type
    // this.$store.commit('setTitle', titles[this.id])
    console.log(CONTENT)
    this.content = CONTENT[this.id]
  }
}
</script>


<style lang="scss">
.manual-detail{
  padding: 52px 15px 70px;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  >div.h1{
    margin-bottom: 10px;
    h1 {
      font-size: 15px;
      color: #131828;
      font-weight: bold;
      margin-bottom: 8px;
    }
    p{
      color: #6E7886;
      font-size: 12px;
    }
    .sub{
      margin-bottom: 16px;
    }
  }
}
</style>